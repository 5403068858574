import { isEqual } from 'lodash'
import {
  useQueryClient, useMutation
} from 'react-query'

import useClient from '../../hooks/useClient'

export const useMutateAdQuery = (adId) => {
  const client = useClient()
  const queryClient = useQueryClient()

  return useMutation(async (values) => {
    const config = {
      timeout: 50000
    }

    await client.patch(`/api/annonces/${adId}`, values, config)
  }, {
    onSuccess: async () => {
      await queryClient.refetchQueries(['fetchMe'])
    }
  })
}

export const useMutateAdStatusQuery = (adId) => {
  const client = useClient()
  const queryClient = useQueryClient()

  return useMutation(async (status) =>
    await client.patch(`/api/annonces/${adId}`, {
      ad: { status_id: status }
    }), {
    onSuccess: async () => {
      await queryClient.refetchQueries(['fetchMe'])
    }
  })
}
