import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'

import SEO from '../../components/atoms/Seo'
import media from '../../utils/media'
import Navbar from '../../components/organisms/Navbar'
import Footer from '../../components/organisms/Footer'
import ProfileInfos from '../../components/organisms/ProfileInfos'
import ProfileAlerts from '../../components/organisms/ProfileAlerts'
import ProfileRequests from '../../components/organisms/ProfileRequests'
import ProfileAnnonces from '../../components/organisms/ProfileAnnonces'
import HomeSectionTitle from '../../components/atoms/HomeSectionTitle'
import { useFetchMeQuery } from '../../queries/users'

const Content = styled.div`
  margin: 74px 0;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-right: 20%;
  margin-left: 20%;

  ${media.lessThan('lg')`
    margin: 74px 24px;
  `}
`

const Account = ({ location }) => {
  const TITLE = 'Trouver Mon Local Pro - Profil'
  const DESCRIPTION = 'Profil utilisateur de Trouver Mon Local Pro'

  const { data, error } = useFetchMeQuery()
  const user = get(data, 'user')

  return (
    <>
      <SEO
        title={TITLE}
        description={DESCRIPTION}>
      </SEO >
      <Navbar location={location} />
      <Content>
        {!isEmpty(error) &&
          <HomeSectionTitle tag='h2'>Impossible de voir votre profil.</HomeSectionTitle>}
        {isEmpty(error) &&
          <>
            <ProfileInfos user={user} />
            <ProfileAnnonces
              ads={get(data, 'ads')}
              user={user} />
            {!isEmpty(get(data, 'requests', [])) && <ProfileRequests requests={get(data, 'requests', [])} />}
            {!isEmpty(get(data, 'alerts', [])) && <ProfileAlerts alerts={get(data, 'alerts', [])} />}
          </>}
      </Content>
      <Footer />
    </>
  )
}

Account.propTypes = {
  location: PropTypes.object.isRequired
}

export default Account
