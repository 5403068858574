import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  get, map, size, isEqual, filter, omit, pickBy, isNumber, includes,
  join, isArray, reduce, isEmpty, isNull, toString
} from 'lodash'

import media from '../../../utils/media'
import Modal from '../../atoms/Modal'
import numeral from '../../../config/number'
import AlertForm from '../../molecules/AlertForm'
import SubmitButton from '../../atoms/SubmitButton'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'
import { formatDate } from '../../../utils/date'
import { useMutateAlertQuery, useDeleteAlertQuery, useMutateAlertStatusQuery } from '../../../queries/alerts'

const ContentBlock = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: space-between;
  gap: 8px;
`
const AdCard = styled.div`
  display: flex;
  gap: 8px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(51, 102, 204, 0.15);
  ${({ disabled }) => disabled ? 'filter: grayscale(80%)' : ''};

  ${media.lessThan('lg')`
    flex-direction: column;
  `}
`
const AdCardContent = styled.div`
  padding: 8px;
  display: flex;
  width: 100%;
  justify-content: space-between;

  ${media.lessThan('lg')`
    flex-direction: column;
  `}
`
const IconBg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  border-radius: 100px;
  color: white;
  background-color: ${({ theme, color }) => get(theme, color, '#fff')};
`
const Icon = styled(FontAwesomeIcon)`
  font-size: ${({ size }) => size || '14'}px;
`
const Tag = styled.div`
  margin: 4px 0;
  padding: 4px;
  width: fit-content;
  border-radius: 4px;
  background-color: ${({ theme, color }) => get(theme, color || 'blue')};
`
const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  min-width: 150px;
`
const Button = styled(SubmitButton)`
  padding: 0px 12px;
  align-self: center;
  min-height: 36px;
  max-height: 36px;
`
const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`

const Inline = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`
const Buttons = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 24px;
`

const ProfileAlerts = ({ alerts }) => {
  const [alert, setAlert] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [openStatusModal, setOpenStatusModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const { mutateAsync } = useMutateAlertStatusQuery(get(alert, 'id'))
  const { mutateAsync: deleteAsync } = useDeleteAlertQuery(get(alert, 'id'))
  const { mutateAsync: updateAsync } = useMutateAlertQuery(get(alert, 'id'))

  const getLabelRooms = (min, max) => {
    if (isNull(min)) {
      return ''
    }

    if (isEqual(min, max)) {
      return `${min} pièce(s)`
    }

    return `de ${min} à ${max} pièce(s)`
  }

  const getLabelWhole = (whole) => {
    if (isNull(whole)) {
      return ''
    }

    return isEqual(whole, true) ? 'Logement entier' : 'Salle privative'
  }

  const getLabelType = (type) => {
    if (isNull(type) || isEmpty(type)) {
      return ''
    }

    return isEqual(type, 'acheter') ? 'Achat' : 'Location'
  }

  const initLocation = (location) => {
    const type = get(location, 'type', '')
    if (isEqual(type, 'all')) {
      return omit(location, ['type'])
    }

    return {
      city: get(location, 'city', []),
      region: get(location, 'region', []),
      department: get(location, 'department', []),
      agglomeration: get(location, 'agglomeration', [])
    }
  }

  const getSearchUrl = (values) => {
    const locationParams = reduce(get(values, 'location'), (acc, val, key) => {
      if (isEmpty(val)) {
        return acc
      }

      const queryParamsForVal = map(val, iter => `${key}=${get(iter, 'id')}`)

      return `${acc}${queryParamsForVal.join('&')}&`
    }, '')

    const hiddenParams = ['location', 'locationInput', 'rooms']
    const params = pickBy(values, (val, key) => (isNumber(val) || !isEmpty(val)) && !includes(hiddenParams, key))

    const queryParams = join(map(params, (val, key) => {
      if (isEqual(key, 'professions') && isEqual(val, 'Toutes professions')) {
        return ''
      }

      if (isArray(val)) {
        return join(map(val, iter => `${key}[]=${iter}`), '&')
      }
      return `${key}=${val}`
    }), '&')

    if (typeof window !== 'undefined') {
      const currentParams = new URLSearchParams()

      const multiValueParams = ['city', 'region', 'department', 'agglomeration']

      const newParams = new URLSearchParams(`${locationParams}${queryParams}`)
      for (const [key, value] of newParams.entries()) {
        if (multiValueParams.includes(key)) {
          currentParams.append(key, value)
        } else {
          currentParams.set(key, value)
        }
      }

      currentParams.set('page', '1')
      const newUrl = `/nos-annonces/?${currentParams.toString()}`
      return newUrl
    }

    return ''
  }

  const handleUpdateModal = useCallback(() => {
    setOpenModal(!openModal)
  }, [openModal])

  const handleUpdateStatusModal = useCallback(() => {
    setOpenStatusModal(!openStatusModal)
  }, [openStatusModal])

  const handleUpdateStatus = useCallback(async () => {
    try {
      const status = get(alert, 'status_id')
      await mutateAsync(isEqual(status, 1) ? 3 : 1)
      setOpenStatusModal(false)
    } catch (e) {
    }
  }, [alert, setOpenStatusModal])

  const handleDeleteModal = useCallback(() => {
    setOpenDeleteModal(!openDeleteModal)
  }, [openDeleteModal])

  const handleDeleteAlert = useCallback(async () => {
    try {
      await deleteAsync()
      setOpenDeleteModal(false)
    } catch (e) {
    }
  }, [setOpenDeleteModal])

  return (
    <div>
      <HomeSectionTitle tag='h2'>Mes alertes ({size(alerts)})</HomeSectionTitle>
      <ContentBlock direction='column'>
        {map(alerts, alert => {
          const status = get(alert, 'status_id')
          const whole = get(alert, 'whole', undefined)
          const budget = get(alert, 'max_price')
          const action = get(alert, 'ad_type')
          const cities = get(alert, 'cities', [])
          const regions = get(alert, 'regions', [])
          const departments = get(alert, 'departments', [])
          const agglomerations = get(alert, 'agglomerations', [])
          const surface = get(alert, 'min_surface')
          const minRooms = get(alert, 'min_rooms')
          const maxRooms = get(alert, 'max_rooms')
          const fulltime = get(alert, 'fulltime')
          const professions = get(alert, 'professioncategory.name', 'Toutes professions')

          const labelBudget = isNull(budget) ? '' : numeral(budget).format('0,0 $')
          const labelRoom = getLabelRooms(minRooms, maxRooms)
          const labelType = getLabelType(action)
          const labelWhole = getLabelWhole(whole)
          const labelSurface = isNull(surface) ? '' : `Minimum ${surface} m2`
          const labelFulltime = isNull(fulltime) ? '' : fulltime ? 'Temps plein' : 'Temps partiel'

          const handleSearch = () => {
            const searchUrl = getSearchUrl({
              whole,
              rooms: filter([minRooms, maxRooms], val => !isNull(val)),
              availability: isNull(fulltime) ? '' : fulltime ? 'full-time' : 'mid-time',
              minRooms,
              maxRooms,
              action,
              budget,
              professions,
              surface,
              location: initLocation({
                type: 'all',
                city: cities,
                region: regions,
                department: departments,
                agglomeration: agglomerations
              })
            })

            navigate(searchUrl)
          }

          return (
            <AdCard
              disabled={isEqual(status, 3)}
              key={`alert-${get(alert, 'id')}`}>
              <AdCardContent>
                <div>
                  <HomeSectionDescription><strong>{get(alert, 'name')}</strong></HomeSectionDescription>
                  <HomeSectionDescription><em>Créée le {formatDate(new Date(get(alert, 'created_at')))}</em></HomeSectionDescription>
                  <Wrap>
                    {!isEmpty(labelType) && <Tag>
                      <HomeSectionDescription color='white'>{labelType}</HomeSectionDescription>
                    </Tag>}
                    {!isEmpty(labelBudget) && <Tag>
                      <HomeSectionDescription color='white'>Budget de {labelBudget}</HomeSectionDescription>
                    </Tag>}
                    {!isEmpty(labelRoom) && <Tag>
                      <HomeSectionDescription color='white'>{labelRoom}</HomeSectionDescription>
                    </Tag>}
                    {!isEmpty(labelSurface) && <Tag>
                      <HomeSectionDescription color='white'>{labelSurface}</HomeSectionDescription>
                    </Tag>}
                    {!isEmpty(labelWhole) && <Tag>
                      <HomeSectionDescription color='white'>{labelWhole}</HomeSectionDescription>
                    </Tag>}
                    {!isEmpty(labelFulltime) && <Tag>
                      <HomeSectionDescription color='white'>{labelFulltime}</HomeSectionDescription>
                    </Tag>}
                    {!isEmpty(get(alert, 'professioncategory')) && <Tag>
                      <HomeSectionDescription color='white'>{get(alert, 'professioncategory.name', '')}</HomeSectionDescription>
                    </Tag>}
                    {map(get(alert, 'cities', []), city => (
                      <Tag key={get(city, 'id')}>
                        <HomeSectionDescription color='white'>{get(city, 'name')}</HomeSectionDescription>
                      </Tag>
                    ))}
                    {map(get(alert, 'departments', []), dpt => (
                      <Tag key={get(dpt, 'id')}>
                        <HomeSectionDescription color='white'>{get(dpt, 'name')}</HomeSectionDescription>
                      </Tag>
                    ))}
                    {map(get(alert, 'agglomerations', []), agg => (
                      <Tag key={get(agg, 'id')}>
                        <HomeSectionDescription color='white'>{get(agg, 'name')}</HomeSectionDescription>
                      </Tag>
                    ))}
                    {map(get(alert, 'regions', []), region => (
                      <Tag key={get(region, 'id')}>
                        <HomeSectionDescription color='white'>{get(region, 'name')}</HomeSectionDescription>
                      </Tag>
                    ))}
                  </Wrap>
                </div>
                <Actions>
                  <Inline>
                    <IconBg
                      color='brightOrange'
                      onClick={() => { setAlert(alert); handleUpdateModal() }}>
                      <Icon
                        icon='pen'
                        size={14}
                        color={'white'} />
                    </IconBg>
                    <IconBg
                      color='blue'
                      onClick={() => { setAlert(alert); handleUpdateStatusModal() }}>
                      <Icon
                        size={14}
                        icon={isEqual(status, 1) ? 'stop' : 'play'}
                        color={'white'} />
                    </IconBg>
                    <IconBg
                      color='red'
                      onClick={() => { setAlert(alert); handleDeleteModal() }}>
                      <Icon
                        size={14}
                        icon='times'
                        color={'white'} />
                    </IconBg>
                  </Inline>
                  <Button onClick={() => handleSearch()}>
                    Voir la recherche
                  </Button>
                </Actions>
              </AdCardContent>
            </AdCard>
          )
        })}
        <Modal
          size='500px'
          isOpen={openStatusModal}
          onRequestClose={handleUpdateStatusModal}>
          <div>
            <>
              <HomeSectionDescription>
                Êtes-vous sûr de vouloir {isEqual(get(alert, 'status_id'), 1) ? 'mettre en pause' : 'activer'} cette alerte ?
              </HomeSectionDescription>
              <Buttons>
                <SubmitButton onClick={handleUpdateStatusModal}>Annuler</SubmitButton>
                <SubmitButton color='blue' onClick={handleUpdateStatus}>Valider</SubmitButton>
              </Buttons>
            </>
          </div>
        </Modal>
        <Modal
          size='500px'
          isOpen={openDeleteModal}
          onRequestClose={handleDeleteModal}>
          <div>
            <>
              <HomeSectionDescription>
                Êtes-vous sûr de vouloir supprimer cette alerte ?
              </HomeSectionDescription>
              <Buttons>
                <SubmitButton onClick={handleDeleteModal}>Annuler</SubmitButton>
                <SubmitButton color='blue' onClick={handleDeleteAlert}>Valider</SubmitButton>
              </Buttons>
            </>
          </div>
        </Modal>
        <Modal
          size='80%'
          top='60'
          isOpen={openModal}
          onRequestClose={setOpenModal}>
          <AlertForm
            search={{
              name: get(alert, 'name'),
              whole: toString(get(alert, 'whole')),
              rooms: filter([get(alert, 'min_rooms'), get(alert, 'max_rooms')], val => !isNull(val)),
              availability: isNull(get(alert, 'fulltime')) ? '' : get(alert, 'fulltime') ? 'full-time' : 'mid-time',
              minRooms: get(alert, 'min_rooms'),
              maxRooms: get(alert, 'max_rooms'),
              action: get(alert, 'ad_type'),
              budget: get(alert, 'max_price'),
              professions: get(alert, 'professioncategory.name', 'Toutes professions'),
              surface: get(alert, 'surface_min'),
              location: initLocation({
                type: 'all',
                city: get(alert, 'cities'),
                region: get(alert, 'regions'),
                department: get(alert, 'departments'),
                agglomeration: get(alert, 'agglomerations'),
              })
            }}
            buttonLabel='Modifier'
            callback={() => setOpenModal(false)}
            mutateAsync={updateAsync}
            handleOpenAlert={() => setOpenModal(false)} />
        </Modal>
      </ContentBlock>
    </div>
  )
}

export default ProfileAlerts
