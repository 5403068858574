import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { useFormik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get, isEmpty, capitalize } from 'lodash'
import * as Yup from 'yup'

import Modal from '../../atoms/Modal'
import media from '../../../utils/media'
import theme from '../../../config/theme'
import FormItems from '../../atoms/FormItems'
import FormError from '../../atoms/FormError'
import SubmitButton from '../../atoms/SubmitButton'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PasswordUpdateForm from '../PasswordUpdateForm'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'
import { PHONE_REGEXP } from '../../../config'
import { useMutateUserQuery } from '../../../queries/users'

const Inline = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  ${({ justify }) => `justify-content: ${justify}` || ''};
`
const ContentBlock = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: space-between;
  gap: 8px;

  ${media.lessThan('lg')`
    flex-direction: column;
  `}
`
const Icon = styled(FontAwesomeIcon)`
  font-size: ${({ size }) => size || '14'}px;
  cursor: pointer;
`
const Underline = styled(HomeSectionDescription)`
  text-decoration: underline;
  cursor: pointer;
  color: ${({ theme, color }) => get(theme, color || 'blue')};
`

const formSchema = Yup.object().shape({
  email: Yup.string().email('Votre email n\'est pas au bon format').required('Votre email est requis'),
  last_name: Yup.string().required('Votre nom est requis'),
  first_name: Yup.string().required('Votre prénom est requis'),
  phone_number: Yup.string().required('Votre numéro de téléphone est requis').matches(PHONE_REGEXP, 'Votre téléphone n\'est pas au bon format')
})

const ITEMS = [{
  type: 'text',
  name: 'first_name',
  placeholder: 'Prénom'
}, {
  type: 'text',
  name: 'last_name',
  placeholder: 'Nom'
}, {
  type: 'email',
  name: 'email',
  placeholder: 'Email'
}, {
  type: 'text',
  name: 'phone_number',
  placeholder: 'N° de téléphone'
}]

const Form = styled.form`
  margin-top: 16px;
`
const Button = styled(SubmitButton)`
  margin-top: 24px;
  width: ${({ width }) => width || 'auto'};
`
const CenterButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

const ProfileInfosForm = ({ user, handleClick }) => {
  const [error, setError] = useState('')
  const { mutateAsync } = useMutateUserQuery(get(user, 'id'))

  const { values, handleSubmit, handleChange, setFieldValue, errors, touched, handleBlur } = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: get(user, 'email', ''),
      last_name: get(user, 'lastname', ''),
      first_name: get(user, 'firstname', ''),
      phone_number: get(user, 'phone', '')
    },
    validationSchema: formSchema,
    onSubmit: async values => {
      try {
        await mutateAsync({ user: values })
        handleClick()
      } catch (e) {
        setError('Impossible de modifier vos informations.')
      }
    }
  })

  return (
    <Form onSubmit={handleSubmit}>
      <FormItems
        width={true}
        items={ITEMS}
        errors={errors}
        values={values}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue} />
      <CenterButton>
        <Button type='submit'>Modifier</Button>
      </CenterButton>
      {!isEmpty(error) && <FormError error={error} />}
    </Form>
  )
}

ProfileInfosForm.propTypes = {
  user: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired
}

const ProfileInfos = ({ user }) => {
  const [open, setOpen] = useState(false)
  const [update, setUpdate] = useState(false)

  const handleClick = useCallback(() => setOpen(!open), [open, setOpen])
  const handleUpdate = useCallback(() => setUpdate(!update), [update, setUpdate])
  const handleLogout = useCallback(() => {
    localStorage.setItem('token', '')
    navigate('/profile/login')
  })

  return (
    <div>
      <Inline>
        <HomeSectionTitle tag='h2'>Mes informations personnelles</HomeSectionTitle>
        <Icon
          icon='pen'
          color={get(theme, 'brightOrange', '#fff')}
          onClick={handleClick} />
      </Inline>
      <ContentBlock>
        <div>
          <HomeSectionDescription><strong>Mes données</strong></HomeSectionDescription>
          <HomeSectionDescription>{capitalize(get(user, 'firstname', ''))} {capitalize(get(user, 'lastname', ''))}</HomeSectionDescription>
          <HomeSectionDescription>{get(user, 'email', '')}</HomeSectionDescription>
          <HomeSectionDescription>{get(user, 'phone', '')}</HomeSectionDescription>
        </div>
        <div>
          <HomeSectionDescription><strong>Mot de passe</strong></HomeSectionDescription>
          <Underline onClick={handleUpdate}>Changer le mot de passe</Underline>
        </div>
        <div>
          <Underline color='brownishGrey' onClick={handleLogout}>Se déconnecter</Underline>
        </div>
      </ContentBlock>
      <Modal
        size='500px'
        isOpen={open}
        onRequestClose={handleClick}>
        <Inline justify='space-between'>
          <HomeSectionTitle tag='h2'>Informations</HomeSectionTitle>
          <Icon
            icon='times'
            size={24}
            color={get(theme, 'blue', '#fff')}
            onClick={handleClick} />
        </Inline>
        <ProfileInfosForm
          user={user}
          handleClick={handleClick} />
      </Modal>
      <Modal
        size='500px'
        isOpen={update}
        onRequestClose={handleUpdate}>
        <Inline justify='space-between'>
          <HomeSectionTitle tag='h2'>Modification du mot de passe</HomeSectionTitle>
          <Icon
            icon='times'
            size={24}
            color={get(theme, 'blue', '#fff')}
            onClick={handleUpdate} />
        </Inline>
        <PasswordUpdateForm handleClose={handleUpdate} />
      </Modal>
    </div>
  )
}

ProfileInfos.propTypes = {
  user: PropTypes.object.isRequired
}

export default ProfileInfos
