import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useFormik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get, map, isEqual, toUpper, filter, includes, last, isNull } from 'lodash'

import Text from '../../atoms/Text'
import media from '../../../utils/media'
import FormItem from '../FormItem'
import Dropdown from '../../atoms/Dropdown'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionDescription from '../HomeSectionDescription'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const Select = styled.select`
  font-family: 'Source Sans Pro';
  max-width: ${({ width }) => width ? '100%' : '365px'};
  height: 45px;
  ${({ width }) => width ? 'width: 100%' : ''};
  padding: 12px 16px;
  font-size: 16px;
  background-clip: padding-box;
  margin-top: 4px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  border-radius: 5px;
  background-color: ${({ theme }) => get(theme, 'white')};
  margin-bottom: ${({ error }) => error ? 5 : 2}px;
  color: ${({ error, theme }) => get(theme, error ? 'lightOrange' : 'blue')};
  border: 1px solid ${({ error, theme }) => get(theme, error ? 'lightOrange' : 'blue')};

  &::placeholder {
    width: 100%;
    height: 25px;
    opacity: 0.5;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    color: ${({ error, theme }) => get(theme, error ? 'lightOrange' : 'blue')};
  }

  ${media.lessThan('xxl')`
    ${({ small }) => small ? 'padding: 0 16px' : ''};
    ${({ small }) => small ? 'height: 34px' : ''};
    ${({ small }) => small ? 'font-size: 14px' : ''};
  `}

  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
`
const Inline = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin: 6px 0;
`
const InlineWrap = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin: 6px 0;
`
const LabelOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 6px;
  background-color: ${({ theme }) => get(theme, 'blue')};
  border-radius: 4px;
  color: white;
`
const Icon = styled(FontAwesomeIcon)`
  cursor: pointer;
`
const Textarea = styled.textarea`
font-family: 'Source Sans Pro';
  max-width: ${({ width }) => width ? '100%' : '365px'};;
  ${({ width }) => width ? 'width: 100%' : ''};
  padding: 12px 16px;
  font-size: 16px;
  background-clip: padding-box;
  margin-top: 4px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  border-radius: 5px;
  background-color: ${({ theme }) => get(theme, 'white')};
  margin-bottom: ${({ error }) => error ? 5 : 2}px;
  color: ${({ error, theme }) => get(theme, error ? 'lightOrange' : 'blue')};
  border: 1px solid ${({ error, theme }) => get(theme, error ? 'lightOrange' : 'blue')};

  &::placeholder {
    width: 100%;
    height: 25px;
    opacity: 0.5;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    color: ${({ error, theme }) => get(theme, error ? 'lightOrange' : 'blue')};
  }

  ${media.lessThan('xxl')`
    ${({ small }) => small ? 'padding: 0 16px' : ''};
    ${({ small }) => small ? 'font-size: 14px' : ''};
  `}
`
const DateButton = styled.div`
  cursor: pointer;
  padding: 8px;
  border: 1px solid ${({ theme }) => get(theme, 'blue')};
  border-radius: 4px;
  background-color: ${({ theme, selected }) => get(theme, selected ? 'blue' : 'white')};
  color: ${({ theme, selected }) => get(theme, selected ? 'white' : 'blue')};
`
const DateInput = styled.input`
  cursor: pointer;
`

export const InputItem = ({ handleChange, disabled, touched, width, errors, field, values, setFieldTouched, setFieldValue, handleBlur }) => {
  const [input, updateInput] = useState(get(values, get(field, 'key')))
  const [selection, updateSelection] = useState(get(values, get(field, 'key')))

  const removeSelection = useCallback((value) => {
    const res = filter(selection, elt => !isEqual(value, elt))
    updateSelection(res)
    setFieldValue(get(field, 'key'), res)
  }, [selection, updateSelection])

  const addSelection = useCallback((event) => {
    if (!includes(selection, event.target.value)) {
      const res = [...selection, event.target.value]
      updateSelection(res)
      setFieldValue(get(field, 'key'), res)
    } else {
      const res = [...selection]
      updateSelection(res)
      setFieldValue(get(field, 'key'), res)
    }
  }, [field, selection, updateSelection])

  const handleDateChange = useCallback((event) => {
    const { value: newValue } = event.target

    setFieldValue('availability', newValue)
  }, [setFieldValue])

  useEffect(() => {
    updateInput(get(values, get(field, 'key')))
  }, [values])

  if (isEqual(get(field, 'type'), 'options')) {
    return (
      <Container>
        <Text strong>
          <Container disabled>
            {toUpper(get(field, 'placeholder', ''))}
            <Select
              name={get(field, 'key')}
              width={width}
              value={get(values, get(field, 'key'))}
              onBlur={handleBlur}
              disabled={disabled}
              onChange={handleChange}>
              {map(get(field, 'options', []), (option) => (
                <option value={get(option, 'value')} label={get(option, 'label', '')}>
                  {get(option, 'label', '')}
                </option>
              ))}
            </Select>
          </Container>
        </Text>
      </Container>
    )
  }

  if (isEqual(get(field, 'type'), 'multiselection')) {
    return (
      <Container>
        <Text strong>
          <Container>
            {toUpper(get(field, 'placeholder', ''))}
            <InlineWrap>{map(selection, selec => {
              const label = isEqual(selec, 'all') ? 'Toutes catégories' : selec
              return (
                <LabelOption>
                  <div>{label}</div>
                  <Icon icon='times' color='white' onClick={() => removeSelection(selec)} />
                </LabelOption>
              )
            })}</InlineWrap>
            <Select
              name={get(field, 'key')}
              width={width}
              value={last(get(values, get(field, 'key')))}
              onBlur={handleBlur}
              onChange={addSelection}>
              <option value="" disabled selected>
                Sélectionnez une ou plusieurs options
              </option>
              {map(get(field, 'options', []), (option) => (
                <option value={get(option, 'value')} label={get(option, 'label', '')}>
                  {get(option, 'label', '')}
                </option>
              ))}
            </Select>
          </Container>
        </Text>
      </Container>
    )
  }

  if (isEqual(get(field, 'type'), 'payments')) {
    return (
      <Container>
        <Text strong>
          <Container>
            {toUpper(get(field, 'placeholder', ''))}
            <Inline>
              <InputItem
                name='amount'
                value={get(values, 'amount', 0.00)}
                width={width}
                squared={true}
                bordered={true}
                disabled
                onChange={handleChange}
                inputSize='regular'
                importance='secondary'
                placeholder={get(field, 'placeholder', '')} />
              <Select
                name={get(field, 'key')}
                value={get(values, get(field, 'key'))}
                onBlur={handleBlur}
                disabled
                onChange={(e) => setFieldValue('payments', e.target.value)}>
                <option value="" disabled selected>
                  Sélectionnez une option
                </option>
                {map(get(field, 'options', []), (option) => (
                  <option value={get(option, 'value')} label={get(option, 'label', '')}>
                    {get(option, 'label', '')}
                  </option>
                ))}
              </Select>
            </Inline>
          </Container>
        </Text>
      </Container>
    )
  }

  if (isEqual(get(field, 'type'), 'dropdown')) {
    return (
      <Container width100={true}>
        <Text textType='subtitle' strong>
          <>
            {toUpper(get(field, 'placeholder', ''))}
            <Dropdown
              dropdownSize='large'
              name={get(field, 'key')}
              value={input}
              width={width}
              options={get(field, 'options', [])}
              multiple={false}
              onChange={setFieldValue}
              selection={input}
              placeholder={get(field, 'placeholder', '')} />
          </>
        </Text>
      </Container>
    )
  }

  if (isEqual(get(field, 'type'), 'checkbox')) {
    return (
      <Container>
        <Text textType='subtitle' strong>
          <Container row gap={4}>
            <input
              type='checkbox'
              name={get(field, 'key')}
              checked={isEqual(get(values, get(field, 'key')), true)}
              onChange={() => {
                if (isEqual(get(values, get(field, 'key')), true)) {
                  setFieldValue(get(field, 'key'), false)
                } else {
                  setFieldValue(get(field, 'key'), true)
                }
                setFieldTouched(get(field, 'key'), true)
              }} />
            <Text>{get(field, 'placeholder', '')}</Text>
          </Container>
        </Text>
      </Container>
    )
  }

  const name = get(field, 'key')

  if (isEqual(get(field, 'type'), 'textarea')) {
    return (
      <Container row={false} width100={true}>
        <Text align='start' textType='subtitle' strong>
          {toUpper(get(field, 'placeholder', ''))}
        </Text>
        <Textarea
          row={10}
          name={name}
          width={width}
          type={get(field, 'type')}
          error={get(touched, name) && get(errors, name)}
          value={get(values, name, '')}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={get(field, 'placeholder', '')}
          data-testid={`input-${name}`} />
      </Container>
    )
  }

  if (isEqual(get(field, 'type'), 'date')) {
    const value = get(values, name, '')

    return (
      <Container row={false} width100={true}>
        <Text strong>

          <Text align='start' textType='subtitle' strong>
            {toUpper(get(field, 'placeholder', ''))}
          </Text>
          <Inline>
            <DateButton
              selected={isNull(value)}
              onClick={() => setFieldValue(name, null)}>
              <div>Immédiatement</div>
            </DateButton>
            <DateInput
              type='date'
              id='availability'
              name='availability'
              onChange={handleDateChange}
              value={isNull(value) ? new Date().toISOString().split('T')[0] : value.split('T')[0]} />
          </Inline>
        </Text>
      </Container>
    )
  }

  return (
    <Container row={false} width100={true}>
      <Text align='start' textType='subtitle' strong>
        {toUpper(get(field, 'placeholder', ''))}
      </Text>
      <FormItem
        name={name}
        width={width}
        type={get(field, 'type')}
        error={get(touched, name) && get(errors, name)}
        value={get(values, name, '')}
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={disabled}
        placeholder={get(field, 'placeholder', '')}
        data-testid={`input-${name}`} />
    </Container>
  )
}

const Form = ({ data, fields, submit }) => {
  const onSubmit = useCallback(async (values) => {
    await submit(values)
  }, [])

  const { values, handleChange, handleSubmit, setFieldTouched, setFieldValue, handleBlur } = useFormik({
    initialValues: data,
    onSubmit
  })

  return (
    <Container gap={16} width100 maxWidth={800} wrap >
      {map(fields, field => (
        <InputItem
          key={get(field, 'key')}
          field={field}
          values={values}
          handleBlur={handleBlur}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched} />
      ))}
      <ButtonContainer
        label={'hehe'}
        color='success'
        onClick={() => handleSubmit()}
        buttonSize='large'
        top={8} />
    </Container>
  )
}

export default Form
