import React, { useRef, useState, useCallback, useEffect } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import imageCompression from 'browser-image-compression'
import { useFormik } from 'formik'
import {
  get, split, forEach, omit, reduce, isUndefined,
  map, isEmpty, isEqual, filter, first
} from 'lodash'

import Text from '../../atoms/Text'
import media from '../../../utils/media'
import Modal from '../../atoms/Modal'
import theme from '../../../config/theme'
import LoadingDots from '../../atoms/LoadingDots'
import SubmitButton from '../../atoms/SubmitButton'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'
import { InputItem } from '../../atoms/InputItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutateAdQuery } from '../../../queries/ads'

const fields = [{
  key: 'ad_type',
  type: 'options',
  options: [{
    value: 'sale',
    label: 'Vendre'
  }, {
    value: 'rental',
    label: 'Location'
  }],
  disabled: true,
  placeholder: 'Type d\'annonce'
}, {
  key: 'name',
  type: 'text',
  width: 'all',
  options: [],
  placeholder: 'Titre'
}, {
  key: 'description',
  type: 'textarea',
  options: [],
  placeholder: 'Description'
}, {
  key: 'address',
  type: 'text',
  options: [],
  placeholder: 'Adresse'
}, {
  key: 'price',
  type: 'number',
  options: [],
  placeholder: 'Prix'
}, {
  key: 'monthly_charge',
  type: 'number',
  options: [],
  placeholder: 'Charges mensuelles'
}, {
  key: 'property_tax',
  type: 'number',
  options: [],
  placeholder: 'Taxes de propriété'
}, {
  key: 'surface',
  type: 'number',
  options: [],
  placeholder: 'Surface du bien'
}, {
  key: 'whole',
  type: 'options',
  options: [{
    value: 'true',
    label: 'Local entier'
  }, {
    value: 'false',
    label: 'Salle privative'
  }],
  placeholder: 'Type de surface'
}, {
  key: 'rooms',
  type: 'number',
  options: [],
  placeholder: 'Nombre de pièce(s)'
}, {
  key: 'floor',
  type: 'number',
  options: [],
  placeholder: 'Etage'
}, {
  key: 'availability',
  type: 'date',
  options: [],
  placeholder: 'Date de disponibilité'
}, {
  key: 'fulltime',
  type: 'options',
  options: [{
    value: 'true',
    label: 'Temps plein'
  }, {
    value: 'false',
    label: 'Temps partiel'
  }],
  placeholder: 'Type de disponibilité'
}, {
  key: 'days',
  type: 'multiselection',
  options: [{
    value: 'Lundi',
    label: 'Lundi'
  }, {
    value: 'Mardi',
    label: 'Mardi'
  }, {
    value: 'Mercredi',
    label: 'Mercredi'
  }, {
    value: 'Jeudi',
    label: 'Jeudi'
  }, {
    value: 'Vendredi',
    label: 'Vendredi'
  }, {
    value: 'Samedi',
    label: 'Samedi'
  }, {
    value: 'Dimanche',
    label: 'Dimanche'
  }],
  placeholder: 'Jours disponibles'
}, {
  key: 'professions',
  type: 'multiselection',
  options: [{
    value: 'all',
    label: 'Toutes catégories'
  }, {
    value: 'Médical',
    label: 'Médical'
  }, {
    value: 'Paramédical',
    label: 'Paramédical'
  }, {
    value: 'Juridique',
    label: 'Juridique'
  }, {
    value: 'Conseil/Finance',
    label: 'Conseil/Finance'
  }, {
    value: 'Autre',
    label: 'Autre'
  }],
  placeholder: 'Types de profession'
}, {
  key: 'extras',
  type: 'multiselection',
  options: [{
    value: 'Ascenceur',
    label: 'Ascenceur'
  }, {
    value: 'Accès mobilité réduite',
    label: 'Accès mobilité réduite'
  }, {
    value: 'Vitrine',
    label: 'Vitrine'
  }, {
    value: 'Interphone',
    label: 'Interphone'
  }, {
    value: 'Gardien',
    label: 'Gardien'
  }, {
    value: 'Climatisation',
    label: 'Climatisation'
  }, {
    value: 'Fibre',
    label: 'Fibre'
  }, {
    value: 'Salle d\'attente',
    label: 'Salle d\'attente'
  }, {
    value: 'Salle de repos',
    label: 'Salle de repos'
  }, {
    value: 'Cuisine',
    label: 'Cuisine'
  }, {
    value: 'Toilettes',
    label: 'Toilettes'
  }, {
    value: 'Salle de bains',
    label: 'Salle de bains'
  }, {
    value: 'Balcon',
    label: 'Balcon'
  }, {
    value: 'Terrasse',
    label: 'Terrasse'
  }, {
    value: 'Parking privatif',
    label: 'Parking privatif'
  }, {
    value: 'Parking mutualisé',
    label: 'Parking mutualisé'
  }, {
    value: 'Local à vélo',
    label: 'Local à vélo'
  }, {
    value: 'Borne de recharge électrique',
    label: 'Borne de recharge électrique'
  }],
  placeholder: 'Caractéristiques'
}, {
  key: 'images',
  type: 'text',
  options: [],
  placeholder: 'Images'
}]

const Inline = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  ${({ width }) => `width: ${width}` || ''};
  ${({ align }) => `align-items: ${align}` || ''};
  ${({ justify }) => `justify-content: ${justify}` || ''};

  ${media.lessThan('lg')`
    ${({ wrap }) => wrap || ''};
  `}
`
const AddressBlock = styled.div`
  border: 1px solid ${({ theme }) => get(theme, 'blue')};
  border-radius: 6px;
  padding: 24px;
  margin-top: 8px;
  margin-bottom: 8px;
`
const GouvAddress = styled.div`
  margin: 8px 0;
  display: flex;
  flex-direction: column;
`
const Address = styled.div`
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => get(theme, 'paleBlue')}
  }
`
const ImageAnnonce = styled.img`
  width: 200px;
  height: 200px;
`
const Icon = styled(FontAwesomeIcon)`
  cursor: pointer;
  z-index: 200;
  font-size: ${({ size }) => size || '14'}px;
`
const Cross = styled.div`
  color: ${({ disabled }) => disabled ? get(theme, 'grey') : 'red'};
  position: relative;
  top: 24px;
  left: 12px;
  cursor: pointer;
  font-weight: 600;
`
const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`
const FormContainer = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  height: 500px;
`
const Button = styled(SubmitButton)`
  min-width: 80px;
  min-height: 44px; 
  max-width: 80px;
  max-height: 44px; 
  padding: 12px 8px;
  cursor: ${({ clickable }) => clickable ? 'pointer' : 'not-allowed'};
`
const GouvAddressButton = styled(SubmitButton)`
  width: auto;
  max-height: 44px;
  margin-top: 16px;
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 95%;
`
const CenterButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`
const ButtonImage = styled.button`
  cursor: ${({ cursor }) => cursor};
`
const ChildModal = styled(Modal)`
  z-index: 1000;
`
const Buttons = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 24px;
`

const MAX_IMAGES = 5

const ProfileAnnoncesForm = ({ ad, open, handleClick }) => {
  const [gouvData, updategouvData] = useState({})
  const imageInputRef = useRef(null)
  const [error, setError] = useState('')
  const [imageError, setImageError] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingImg, setLoadingImg] = useState(false)
  const [confirmation, setConfirmation] = useState(false)
  const { mutateAsync } = useMutateAdQuery(get(ad, 'id'))

  const onSubmit = useCallback(async (values) => {
    if (loading) {
      return
    }

    setLoading(true)

    const formData = new FormData()
    const uploadedImages = get(values, 'uploaded_images', [])
    forEach(uploadedImages, file => {
      formData.append('ad[uploaded_images][]', file)
    })

    const removedImages = get(values, 'images_to_remove', [])
    forEach(removedImages, id => {
      formData.append('ad[images_to_remove][]', id)
    })

    const valuesWithoutImages = omit(values, ['images', 'images_to_remove', 'uploaded_images'])
    Object.keys(valuesWithoutImages).forEach(key => {
      formData.append(`ad[${key}]`, valuesWithoutImages[key])
    })

    try {
      await mutateAsync(formData)

      handleClick()
    } catch {
      setError('Une erreur est survenue durant la modification des données')
    } finally {
      setLoading(false)
    }
  }, [loading])

  const { values, touched, resetForm, handleChange, setFieldTouched, handleSubmit, setFieldValue, handleBlur } = useFormik({
    initialValues: {
      lat: get(ad, 'lat'),
      lng: get(ad, 'lng'),
      city: get(ad, 'display_city'),
      name: get(ad, 'name'),
      days: isEmpty(get(ad, 'days')) ? '' : split(get(ad, 'days'), ','),
      floor: get(ad, 'max_floor'),
      rooms: get(ad, 'max_rooms'),
      insee: '',
      price: get(ad, 'min_price'),
      whole: get(ad, 'whole'),
      extras: split(get(ad, 'extras'), ','),
      ad_type: get(ad, 'adType'),
      images: get(ad, 'images'),
      address: get(ad, 'address'),
      zipcode: get(ad, 'zipcode'),
      surface: get(ad, 'surface'),
      fulltime: isEmpty(get(ad, 'days')) ? 'true' : 'false',
      payments: first(map(get(ad, 'payments'), item => get(item, 'slug'))),
      professions: isEmpty(get(ad, 'professions')) ? ['all'] : split(get(ad, 'professions'), ','),
      uploaded_images: [],
      images_to_remove: [],
      description: get(ad, 'description'),
      availability: get(ad, 'availability'),
      property_tax: get(ad, 'property_tax'),
      monthly_charge: get(ad, 'monthly_charge')
    },
    onSubmit
  })

  const searchAPIgouv = useCallback(async () => {
    const address = get(values, 'address')
    const city = get(values, 'city')
    const zipcode = get(values, 'zipcode')

    const queryParams = reduce([address, city, zipcode], (acc, elt) => {
      return isUndefined(elt) || isEmpty(elt) ? acc : [...acc, elt]
    }, [])

    const queryString = queryParams.join(', ')
    const res = await axios.get('https://api-adresse.data.gouv.fr/search', {

      params: {
        q: queryString
      }
    })

    updategouvData(get(res, 'data.features'))
  }, [values])

  const handleAddressSelected = useCallback((elt) => {
    setFieldValue('city', get(elt, 'properties.city'))
    setFieldValue('zipcode', get(elt, 'properties.postcode'))
    setFieldValue('insee', get(elt, 'properties.citycode'))
    setFieldValue('address', get(elt, 'properties.name'))
    setFieldValue('lat', get(elt, 'geometry.coordinates')[1])
    setFieldValue('lng', get(elt, 'geometry.coordinates')[0])

    updategouvData({})
  }, [values])

  const handleAddImage = useCallback(() => {
    const currentNumberOfImages = get(values, 'uploaded_images', []).length + get(values, 'images', []).length
    if (currentNumberOfImages >= MAX_IMAGES) {
      setImageError(`Vous ne pouvez télécharger que ${MAX_IMAGES} images.`)
      return
    }
    if (imageInputRef.current != null) {
      imageInputRef.current.click()
    }
  }, [values, setImageError])

  const handleImageChange = useCallback(async (event) => {
    if (!(event.target.files)) {
      return
    }

    setLoadingImg(true)
    const existingImagesCount = get(values, 'uploaded_images', []).length + get(values, 'images', []).length
    let files = Array.from(event.target.files)

    if (existingImagesCount + files.length > MAX_IMAGES) {
      setImageError(`Vous pouvez télécharger seulement ${MAX_IMAGES - existingImagesCount} images supplémentaires.`)
      files = files.slice(0, MAX_IMAGES - existingImagesCount)
    }

    const compressedFiles = await Promise.all(files.map(async (file) => {
      const options = {
        maxSizeMB: 0.5, // La taille maximale en Mo
        maxWidthOrHeight: 1920, // Largeur/hauteur maximale
        useWebWorker: true // Active l'utilisation de web workers pour de meilleures performances
      }

      try {
        const compressedFile = await imageCompression(file, options)
        return compressedFile
      } catch (error) {
        return file
      } finally {
        setLoadingImg(false)
      }
    }))

    setFieldValue('uploaded_images', compressedFiles)
  }, [values, setFieldValue, setLoadingImg])

  const handleDeleteImage = useCallback((id) => {
    const filtre = filter(get(values, 'images'), img => !isEqual(get(img, 'id'), id))
    setFieldValue('images', filtre)
    setFieldValue('images_to_remove', [...get(values, 'images_to_remove'), id])
  }, [values])

  const handleDeleteFile = useCallback((name) => {
    const filtre = filter(get(values, 'uploaded_images'), file => !isEqual(get(file, 'name'), name))
    setFieldValue('uploaded_images', filtre)
  }, [values])

  const getPlaceholder = (ad, key, placeholder) => {
    if (isEqual(key, 'price')) {
      return isEqual(get(ad, 'adType'), 'sale') ? 'Prix' : 'Loyer'
    }

    return placeholder
  }

  const handleUpdateModal = useCallback(() => {
    if (isEmpty(touched)) {
      handleClick()
    }

    setConfirmation(!confirmation)
  }, [touched, handleClick, confirmation, setConfirmation])

  useEffect(() => {
    const initialValues = {
      lat: get(ad, 'lat'),
      lng: get(ad, 'lng'),
      city: get(ad, 'display_city'),
      name: get(ad, 'name'),
      days: isEmpty(get(ad, 'days')) ? '' : split(get(ad, 'days'), ','),
      floor: get(ad, 'max_floor'),
      rooms: get(ad, 'max_rooms'),
      insee: '',
      price: get(ad, 'min_price'),
      whole: get(ad, 'whole'),
      extras: split(get(ad, 'extras'), ','),
      ad_type: get(ad, 'adType'),
      images: get(ad, 'images'),
      address: get(ad, 'address'),
      zipcode: get(ad, 'zipcode'),
      surface: get(ad, 'surface'),
      fulltime: isEmpty(get(ad, 'days')) ? 'true' : 'false',
      payments: first(map(get(ad, 'payments'), item => get(item, 'slug'))),
      professions: isEmpty(get(ad, 'professions')) ? ['all'] : split(get(ad, 'professions'), ','),
      uploaded_images: [],
      images_to_remove: [],
      description: get(ad, 'description'),
      availability: get(ad, 'availability'),
      property_tax: get(ad, 'property_tax'),
      monthly_charge: get(ad, 'monthly_charge')
    }

    resetForm({ values: initialValues })
  }, [ad, resetForm])

  return (
    <Modal
      size='80%'
      top='60'
      isOpen={open}
      onRequestClose={handleUpdateModal}>
      <FormContainer>
        <Inline
          width='100%'
          align='center'
          justify='space-between'>
          <HomeSectionTitle>Annonce</HomeSectionTitle>
          <Icon
            size={24}
            icon='times'
            color={get(theme, 'blue', '#fff')}
            onClick={handleUpdateModal} />
        </Inline>
        <Form onSubmit={handleSubmit}>
          {map(fields, ({ key, placeholder, type, disabled, options, width }, index) => {
            if (isEqual(key, 'address')) {
              return (
                <AddressBlock>
                  <Inline wrap='flex-wrap: wrap'>
                    <InputItem
                      key={`address-${index}`}
                      width={true}
                      field={{ key, placeholder, type, options }}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched} />
                    <InputItem
                      key={`city-${index}`}
                      width={true}
                      field={{ key: 'city', placeholder: 'Ville', type, options }}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched} />
                    <InputItem
                      key={`zipcode-${index}`}
                      width={true}
                      field={{ key: 'zipcode', placeholder: 'Code postal', type, options }}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched} />
                  </Inline>
                  <Inline wrap='flex-wrap: wrap'>
                    <GouvAddressButton
                      type='button'
                      disabled={loadingImg || loading}
                      onClick={searchAPIgouv}>
                      chercher l&apos;adresse
                    </GouvAddressButton>
                    {!isEmpty(gouvData) &&
                      <Inline align='baseline'>
                        <GouvAddress>{map(gouvData, elt => {
                          return (
                            <div>
                              <Address onClick={() => handleAddressSelected(elt)}>
                                <HomeSectionDescription>{get(elt, 'properties.label')}</HomeSectionDescription>
                              </Address>
                            </div>
                          )
                        })}
                        </GouvAddress>
                        <Icon
                          icon='times'
                          color={get(theme, 'blue', '#fff')}
                          onClick={() => updategouvData({})} />
                      </Inline>}
                  </Inline>
                </AddressBlock>
              )
            }

            if (isEqual(key, 'images')) {
              return (
                <div>
                  <Inline>
                    <Text fontSize={20} strong={true}>IMAGES</Text>
                    <ButtonImage
                      type='button'
                      cursor={loadingImg || loading ? 'not-allowed' : 'pointer'}
                      disabled={loadingImg || loading}
                      onClick={handleAddImage}>
                      ajouter
                    </ButtonImage>
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      style={{ display: 'none' }}
                      ref={imageInputRef}
                      onChange={handleImageChange}
                    />
                  </Inline>
                  <Text color='brightOrange' >{imageError}</Text>
                  <div>
                    {loadingImg && <LoadingDots />}
                    {map(get(values, 'uploaded_images'), elt => {
                      return (
                        <Inline>
                          <Text>{get(elt, 'name')}</Text>
                          <div onClick={() => (!loadingImg && !loading) && handleDeleteFile(get(elt, 'name'))}>
                            <Text color='danger' >X</Text>
                          </div>
                        </Inline>
                      )
                    })}
                  </div>
                  <Wrapper>
                    {map(get(values, 'images'), img => {
                      return <div>
                        <Cross
                          disabled={loadingImg || loading}
                          onClick={() => (!loadingImg && !loading) && handleDeleteImage(get(img, 'id'))}>
                          supprimer
                        </Cross>
                        <ImageAnnonce src={get(img, 'url')} />
                      </div>
                    })}
                  </Wrapper>
                </div>
              )
            }

            if (isEqual(key, 'whole') && isEqual(get(values, 'ad_type'), 'sale')) {
              return null
            }

            if (isEqual(key, 'days') && isEqual(get(values, 'fulltime'), 'true')) {
              return null
            }

            return (
              <InputItem
                key={index}
                width={true}
                field={{ key, placeholder: getPlaceholder(ad, key, placeholder), type, options, width }}
                values={values}
                disabled={disabled}
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched} />
            )
          })}
          {!isEmpty(error) && <Text color='danger'>{error}</Text>}
          <CenterButton>
            <Button
              type='submit'
              disabled={loading || loadingImg}
              clickable={!loading && !loadingImg}
              variant='success'>
              {loading && <LoadingDots />}
              {!loading && 'Valider'}
            </Button>
          </CenterButton>
        </Form >
      </FormContainer >
      <ChildModal
        isOpen={confirmation}
        onRequestClose={() => setConfirmation(false)}>
        <HomeSectionDescription>
          Quitter sans sauvegarder ?
        </HomeSectionDescription>
        <Buttons>
          <SubmitButton onClick={() => setConfirmation(false)}>Annuler</SubmitButton>
          <SubmitButton color='blue' onClick={() => {
            setConfirmation(false)
            handleClick()
            resetForm()
          }}>Valider</SubmitButton>
        </Buttons>
      </ChildModal>
    </Modal>
  )
}

ProfileAnnoncesForm.propTypes = {
  ad: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired
}

export default ProfileAnnoncesForm
