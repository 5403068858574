import React, { useState } from 'react'
import styled from 'styled-components'
import { get, first, isEqual } from 'lodash'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import FormItems from '../../atoms/FormItems'
import FormError from '../../atoms/FormError'
import SubmitButton from '../../atoms/SubmitButton'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'
import { useMutateUserPwdUpdateQuery } from '../../../queries/users'

const formSchema = Yup.object().shape({
  password: Yup.string().required('Votre mot de passe actuel est requis'),
  newPassword: Yup.string().required('Un nouveau mot de passe est requis'),
  newPasswordConfirmation:
    Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Les mots de passe doivent correspondre')
      .required('Un nouveau mot de passe est requis')
})

const items = [{
  type: 'password',
  name: 'password',
  placeholder: 'Mot de passe actuel'
}, {
  type: 'password',
  name: 'newPassword',
  placeholder: 'Nouveau mot de passe'
}, {
  type: 'password',
  name: 'newPasswordConfirmation',
  placeholder: 'Confirmation du nouveau mot de passe'
}]

const Button = styled(SubmitButton)`
  width: fit-content;
  margin-top: 8px;
`
const Form = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const ModalContent = styled.div`
  padding: 24px;
`
const CenterButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

const PasswordUpdateForm = () => {
  const [error, setError] = useState('')
  const [valid, setValid] = useState(false)

  const { mutateAsync } = useMutateUserPwdUpdateQuery()
  const { values, handleChange, handleSubmit, errors, touched, setFieldValue, handleBlur } = useFormik({
    initialValues: {
      password: '',
      newPassword: '',
      newPasswordConfirmation: ''
    },
    validationSchema: formSchema,
    onSubmit: async values => {
      try {
        await mutateAsync(values)
        setValid(true)
      } catch (e) {
        const cause = first(get(e, 'response.data.errors'))
        setError(isEqual(cause, 'Mot de passe incorrect') ? 'Mot de passe incorrect' :  'Impossible de modifier vos informations.')
      }
    }
  })

  return (
    <>
      {!valid &&
        <Form>
          <FormItems
            items={items}
            width={true}
            errors={errors}
            values={values}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue} />
          <FormError error={error} />
          <CenterButton>
            <Button onClick={() => handleSubmit(values)}>Changer de mot de passe</Button>
          </CenterButton>
        </Form>
      }
      {valid && <ModalContent>
        <HomeSectionDescription>Votre mot de passe a bien été modifié !</HomeSectionDescription>
      </ModalContent>}
    </>
  )
}

export default PasswordUpdateForm
