import styled from 'styled-components'
import { get } from 'lodash'

const Text = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  ${({ align }) => align || `text-align: ${align}`};
  color: ${({ theme, color }) => get(theme, color, '#0049ee')};
`

export default Text
