import React from 'react'
import styled from 'styled-components'
import { get, map, size, isEqual } from 'lodash'

import media from '../../../utils/media'
import SubmitButton from '../../atoms/SubmitButton'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'
import { formatDate } from '../../../utils/date'

const ContentBlock = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: space-between;
  gap: 8px;
`
const AdCard = styled.div`
  display: flex;
  gap: 8px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(51, 102, 204, 0.15);
  ${({ disabled }) => disabled ? 'filter: grayscale(80%)': ''};

  ${media.lessThan('lg')`
    flex-direction: column;
  `}
`
const Image = styled.img`
  width: 150px;
  min-width: 150px;
  height: 150px;
  min-height: 150px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
`
const AdCardContent = styled.div`
  padding: 8px;
  display: flex;
  width: 100%;
  justify-content: space-between;

  ${media.lessThan('lg')`
    flex-direction: column;
  `}
`
const Tag = styled.div`
  margin: 4px 0;
  padding: 4px;
  width: fit-content;
  border-radius: 4px;
  background-color: ${({ theme, color }) => get(theme, color || 'blue')};
`
const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
`
const Button = styled(SubmitButton)`
  padding: 0px 12px;
  align-self: center;
  min-height: 36px;
`
const Link = styled.a`
  text-decoration: none;
  font-family: 'Source Sans Pro';
  color: ${({ theme, color }) => get(theme, color || 'blue')};
`

const ProfileRequests = ({ requests }) => {
  const getAdType = (adType) => {
    if (isEqual(adType, 'sale')) {
      return 'Vente'
    }

    return 'Location'
  }

  return (
    <div>
      <HomeSectionTitle tag='h2'>Annonces contactées ({size(requests)})</HomeSectionTitle>
      <ContentBlock direction='column'>
        {map(requests, request => {
          const status = get(request, 'ad.status')

          return (
            <AdCard disabled={isEqual(status, 3)}>
              <Image src={get(request, 'ad.display_img')} />
              <AdCardContent>
                <div>
                  <HomeSectionDescription><strong>{get(request, 'ad.name')}</strong></HomeSectionDescription>
                  <HomeSectionDescription><em>Contactée le {formatDate(new Date(get(request, 'created_at')))}</em></HomeSectionDescription>
                  <Tag><HomeSectionDescription color='white'>{getAdType(get(request, 'ad.adType', ''))}</HomeSectionDescription></Tag>
                  <HomeSectionDescription>{get(request, 'ad.address')}, {get(request, 'ad.display_city')} ({get(request, 'ad.zipcode')})</HomeSectionDescription>
                  <HomeSectionDescription>{get(request, 'ad.max_rooms')} pièce(s)</HomeSectionDescription>
                  <HomeSectionDescription>{get(request, 'ad.surface')} m2</HomeSectionDescription>
                </div>
                <Actions>
                  <Button>
                    <Link color='white' href={`/nos-annonces/ad/${get(request, 'ad.id')}`} target='_blank' rel="noreferrer">
                      Voir l'annonce
                    </Link>
                  </Button>
                </Actions>
              </AdCardContent>
            </AdCard>
          )
        })}
      </ContentBlock>
    </div>
  )
}

export default ProfileRequests
