import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  get, map, size, some, isEqual, isEmpty
} from 'lodash'

import Modal from '../../atoms/Modal'
import media from '../../../utils/media'
import numeral from '../../../config/number'
import SubmitButton from '../../atoms/SubmitButton'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import ProfileAnnoncesForm from '../../molecules/ProfileAnnoncesForm'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'
import { formatDate } from '../../../utils/date'
import { useMutateAdStatusQuery } from '../../../queries/ads'

const ContentBlock = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: space-between;
  gap: 8px;
`
const AdCard = styled.div`
  display: flex;
  gap: 8px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(51, 102, 204, 0.15);
  ${({ disabled }) => disabled ? 'filter: grayscale(80%)' : ''};

  ${media.lessThan('lg')`
    flex-direction: column;
  `}
`
const Image = styled.img`
  width: 150px;
  min-width: 150px;
  height: 150px;
  min-height: 150px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
`
const AdCardContent = styled.div`
  padding: 8px;
  display: flex;
  width: 100%;
  justify-content: space-between;

  ${media.lessThan('lg')`
    flex-direction: column;
  `}
`
const Button = styled(SubmitButton)`
  padding: 0px 12px;
  align-self: center;
  min-height: 36px;
`
const Link = styled.a`
  text-decoration: none;
  font-family: 'Source Sans Pro';
  color: ${({ theme, color }) => get(theme, color || 'blue')};
`
const IconBg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  border-radius: 100px;
  color: white;
  background-color: ${({ theme, color }) => get(theme, color, '#fff')};
`
const Icon = styled(FontAwesomeIcon)`
  font-size: ${({ size }) => size || '14'}px;
`
const Inline = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`
const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
`
const Buttons = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 24px;
`
const Tag = styled.div`
  margin: 4px 0;
  padding: 4px;
  width: fit-content;
  border-radius: 4px;
  background-color: ${({ theme, color }) => get(theme, color || 'blue')};
`
const Title = styled.p`
  margin-bottom: 8px;
  font-weight: 600;
`

const getLabelStatus = (ad) => {
  const isSubscription = some(get(ad, 'payments', []), elt => get(elt, 'subscription', false))
  const isPlay = isEqual(get(ad, 'status'), 1)

  if (!isSubscription) {
    return `Êtes-vous sûr de vouloir ${isPlay ? 'mettre en pause' : 'mettre en ligne'} cette annonce ?`
  }

  if (isPlay) {
    return (
      <div>
        <Title>Êtes-vous sûr de vouloir mettre en pause votre annonce ?</Title>
        Votre annonce sera désactivée et vous ne recevrez plus de demandes de clients.<br />
        ATTENTION, cela n’annulera pas votre abonnement en cours.<br />
        Si vous souhaitez arrêtez votre abonnement, il faut supprimer votre annonce.
      </div>
    )
  }

  return 'Êtes-vous sûr de vouloir mettre en ligne votre annonce ?'
}

const getLabelDelete = (ad) => {
  const isSubscription = some(get(ad, 'payments', []), elt => get(elt, 'subscription', false))

  if (isSubscription) {
    return (
      <div>
        <Title>Êtes-vous sûr de vouloir supprimer votre annonce ?</Title>
        Votre annonce sera définitivement supprimée et vous ne pourrez plus l’activer de nouveau.<br />
        En supprimant votre annonce, le renouvellement de votre abonnement sera annulé et aucun nouveau paiement ne sera réalisé.
      </div>
    )
  }

  return (
    <div>
      <Title>Êtes-vous sûr de vouloir supprimer votre annonce ?</Title>
      Votre annonce sera définitivement supprimée et vous ne pourrez plus l’activer de nouveau.<br />
    </div>
  )
}

const ProfileAnnonces = ({ ads, user }) => {
  const [ad, setAd] = useState({})
  const [open, setOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const blocked = get(user, 'blocked', false)

  const { mutateAsync } = useMutateAdStatusQuery(get(ad, 'id'))

  const handleUpdateModal = useCallback(() => setOpen(!open), [open, setOpen])
  const handleUpdateModalConfirmation = useCallback(() => {
    setOpenModal(!openModal)
  }, [openModal, setOpenModal])
  const handleDeleteModalConfirmation = useCallback(() => setOpenDeleteModal(!openDeleteModal), [openDeleteModal, setOpenDeleteModal])
  const handleContact = useCallback(() => {
    navigate('/nous-contacter/?subject=Nombre maximum d’annonces atteint')
  }, [])

  const handleUpdateStatus = useCallback(async (ad) => {
    const status = get(ad, 'status')
    await mutateAsync(isEqual(status, 1) ? 3 : 1)
    setOpenModal(false)
  }, [])

  const handleDeleteStatus = useCallback(async () => {
    await mutateAsync(4)
    setOpenDeleteModal(false)
  }, [])

  const getAdType = (adType) => {
    if (isEqual(adType, 'sale')) {
      return 'Vente'
    }

    return 'Location'
  }

  const getAdPayment = (name) => {
    if (isEqual(name, 'Boost')) {
      return 'à la Une'
    }

    if (isEqual(name, 'Premium')) {
      return 'premium'
    }

    return 'classique'
  }

  const blockedCondition = (ad, user) => {
    return (isEmpty(get(ad, 'payments', [])) || !some(get(ad, 'payments', []), elt => isEqual(get(elt, 'status'), 1)))
      && isEqual(get(ad, 'status'), 3)
      && isEqual(get(user, 'is_pro', false), false)
  }

  return (
    <div>
      <HomeSectionTitle tag='h2'>Annonces publiées ({size(ads)})</HomeSectionTitle>
      <ContentBlock direction='column'>
        {map(ads, ad => {
          const status = get(ad, 'status')

          return (
            <AdCard disabled={isEqual(status, 3)}>
              <Image src={get(ad, 'display_img')} />
              <AdCardContent>
                <div>
                  <HomeSectionDescription><strong>{get(ad, 'name')}</strong></HomeSectionDescription>
                  <HomeSectionDescription><em>Crée le {formatDate(new Date(get(ad, 'created_at')))}</em></HomeSectionDescription>
                  {map(get(ad, 'payments', []), ({ name, amount, status: statusId }) => {
                    if (isEqual(statusId, 3)) {
                      return null
                    }

                    return (
                      <HomeSectionDescription>
                        Annonce {getAdPayment(name)} (<strong>{numeral(parseFloat(amount)).format('0,0.00 $')}</strong>)
                      </HomeSectionDescription>
                    )
                  })}
                  <Tag><HomeSectionDescription color='white'>{getAdType(get(ad, 'adType', ''))}</HomeSectionDescription></Tag>
                  <HomeSectionDescription>{get(ad, 'address')}, {get(ad, 'display_city')} ({get(ad, 'zipcode')})</HomeSectionDescription>
                  <HomeSectionDescription>{get(ad, 'max_rooms')} pièce(s)</HomeSectionDescription>
                  <HomeSectionDescription>{get(ad, 'surface')} m2</HomeSectionDescription>
                </div>
                <Actions>
                  <Inline>
                    <IconBg
                      color='brightOrange'
                      onClick={() => { handleUpdateModal(); setAd(ad) }}>
                      <Icon
                        icon='pen'
                        size={14}
                        color={'white'} />
                    </IconBg>
                    <IconBg
                      color='blue'
                      onClick={() => {
                        setAd(ad);
                        if (blockedCondition(ad, user)) {
                          const page = isEqual(get(ad, 'adType'), 'rental') ? 'offres-abonnement-location' : 'offres-abonnement-vente'
                          navigate(`/nos-annonces/deposer-une-annonce/${page}/?ad=${get(ad, 'id')}`)
                        } else {
                          handleUpdateModalConfirmation()
                        }
                      }}>
                      <Icon
                        size={14}
                        icon={isEqual(status, 1) ? 'stop' : 'play'}
                        color={'white'} />
                    </IconBg>
                    <IconBg
                      color='red'
                      onClick={() => { handleDeleteModalConfirmation(); setAd(ad) }}>
                      <Icon
                        size={14}
                        icon='times'
                        color={'white'} />
                    </IconBg>
                  </Inline>
                  <Button>
                    <Link color='white' href={`/nos-annonces/ad/${get(ad, 'id')}`} target='_blank' rel="noreferrer">
                      Voir l'annonce
                    </Link>
                  </Button>
                </Actions>
              </AdCardContent>
            </AdCard>
          )
        })}
      </ContentBlock>
      <ProfileAnnoncesForm
        ad={ad}
        open={open}
        handleClick={handleUpdateModal} />
      <Modal
        size='500px'
        isOpen={openModal}
        onRequestClose={handleUpdateModalConfirmation}>
        <div>
          {(!blocked || blocked && isEqual(get(ad, 'status'), 1)) &&
            <>
              <HomeSectionDescription>
                {getLabelStatus(ad)}
              </HomeSectionDescription>
              <Buttons>
                <SubmitButton onClick={handleUpdateModalConfirmation}>Annuler</SubmitButton>
                <SubmitButton color='blue' onClick={() => handleUpdateStatus(ad)}>Valider</SubmitButton>
              </Buttons>
            </>
          }
          {blocked && isEqual(get(ad, 'status'), 3) &&
            <>
              <HomeSectionDescription>
                <strong>Vous avez déjà atteint le nombre maximum d’annonces en ligne sur votre compte.<br /></strong>
                Pour continuer à publier, veuillez mettre en pause l’une de vos annonces actives ou contactez-nous afin d’augmenter votre abonnement.
              </HomeSectionDescription>
              <Buttons>
                <SubmitButton onClick={handleUpdateModalConfirmation}>Annuler</SubmitButton>
                <SubmitButton
                  color='blue'
                  onClick={handleContact}>Nous contacter</SubmitButton>
              </Buttons>
            </>
          }
        </div>
      </Modal>
      <Modal
        size='500px'
        isOpen={openDeleteModal}
        onRequestClose={handleDeleteModalConfirmation}>
        <div>
          <HomeSectionDescription>
            {getLabelDelete(ad)}
          </HomeSectionDescription>
          <Buttons>
            <SubmitButton onClick={handleDeleteModalConfirmation}>Annuler</SubmitButton>
            <SubmitButton color='blue' onClick={handleDeleteStatus}>Valider</SubmitButton>
          </Buttons>
        </div>
      </Modal>
    </div>
  )
}

ProfileAnnonces.propTypes = {
  ads: PropTypes.array.isRequired
}

export default ProfileAnnonces
